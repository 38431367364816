import ExperienceHeader from "../../../../src/components/experience-header";
import AnimatedText from "../../../../src/components/animated-text";
import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import Skills from "../../../../src/components/skills";
import * as React from 'react';
export default {
  ExperienceHeader,
  AnimatedText,
  Layout,
  SEO,
  Skills,
  React
};